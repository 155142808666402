<template>
  <div class="ticket-closer-detail" v-if="getPermission('closer:view')">
    <v-container fluid class="custom-bthrust-style">
      <v-container>
        <v-layout class="justify-end">
          <div class="mr-2">
            <v-text-field
              v-model.trim="filter.search"
              v-on:click:clear="clearFilter('search')"
              clearable
              dense
              filled
              flat
              label="Search"
              hide-details
              solo
              :disabled="closerLoading"
              color="cyan"
              @keydown.enter="getTicketCloser"
              @keydown.tab="getTicketCloser"
            ></v-text-field>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.visit"
              hide-details
              :items="visitList"
              clearable
              dense
              flat
              filled
              label="Visit"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="closerLoading"
              item-text="barcode"
              item-value="id"
              v-on:change="getTicketCloser"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Visit(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                  {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                  {{ formatTime(item.finished_at) }})
                </p>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                    >{{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="font-size-14 pl-2 text-ellipsis pb-1"
                    >{{ item.title }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.engineer"
              hide-details
              :items="engineerList"
              clearable
              dense
              flat
              filled
              label="Engineer"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="closerLoading"
              item-text="display_name"
              item-value="id"
              v-on:change="getTicketCloser"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Engineer(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >#{{ item.barcode }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{ item.primary_email }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{ item.primary_phone }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Visit Date Range"
                  hide-details
                  solo
                  :disabled="closerLoading"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on:click:clear="clearFilter('dates')"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                color="cyan"
                range
                :disabled="closerLoading"
                v-on:change="getTicketCloser"
                v-model="dates"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2 text-right">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="closerLoading"
                  v-on:click="getTicketCloser"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="closerLoading"
                  v-on:click="getTicketCloser"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <template v-if="false && getPermission('closer:create')">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    :disabled="closerLoading"
                    v-on:click="addcloserDialog = true"
                  >
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Create Closer</span>
              </v-tooltip>
            </template>
          </div>
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@10"
        class="custom-skeleton table-rows-text"
        v-if="closerLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr class="custom-border-bottom">
              <td class="font-size-16 font-weight-700" width="100">#</td>
              <td class="font-size-16 font-weight-700" width="200">
                Timesheet #
              </td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Engineer
              </td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Visit
              </td>
              <td class="font-size-16 font-weight-700">Details</td>
              <!-- <td class="font-size-16 font-weight-700">Service/Action Taken</td> -->
              <td class="font-size-16 font-weight-700" align="center">
                Action
              </td>
            </tr>
          </thead>
          <tbody>
            <template v-if="lodash.isEmpty(ticketClosers) === false">
              <template v-for="(row, index) in ticketClosers">
                <v-hover v-slot="{ hover }" :key="index">
                  <tr
                    link
                    :key="'ticket-closer-row' + index"
                    :class="{
                      'ticket-closer-row-hover': hover,
                      'custom-border-bottom': ticketClosers[index + 1],
                    }"
                    v-on:click="detailTicketCloser(row)"
                  >
                    <td class="font-size-16 font-weight-500" width="100">
                      <Barcode :barcode="row.barcode"></Barcode>
                    </td>
                    <td class="font-size-16 font-weight-500" width="200">
                      <span v-if="row.timesheet">
                        <Barcode :barcode="row.timesheet.barcode"></Barcode>
                      </span>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <v-list-item-avatar size="50" v-if="row.engineer">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              v-bind="attrs"
                              v-on="on"
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(row.engineer.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto white--background"
                              transition="fade-transition"
                            ></v-img>
                          </template>
                          <span>{{ row.engineer.display_name }}</span>
                        </v-tooltip>
                      </v-list-item-avatar>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <template v-if="row.visit">
                        <Barcode :barcode="row.visit.barcode"></Barcode>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ formatDateTime(row.visit.started_at) }} -
                          {{ formatTime(row.visit.finished_at) }}
                        </p>
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td class="font-size-16 font-weight-500">
                      <p
                        v-if="row.details"
                        class="m-0 custom-nowrap-ellipsis max-width-400px"
                      >
                        <span style="white-space: break-spaces">{{
                          row.details
                        }}</span>
                      </p>
                      <em v-else class="text--secondary">No Details</em>
                    </td>
                    <!-- <td class="font-size-16 font-weight-500">
                      <p
                        v-if="row.service_actiontaken"
                        class="m-0 custom-nowrap-ellipsis max-width-400px"
                      >
                        <span style="white-space: break-spaces">{{
                          row.service_actiontaken
                        }}</span>
                      </p>
                      <em v-else class="text--secondary"
                        >No Service/Action Taken</em
                      >
                    </td> -->
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="cyan darken-4"
                            v-on:click.stop.prevent="editRow(row)"
                            icon
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>

                        <span>Edit Close</span>
                      </v-tooltip>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!row.pdf_url"
                            color="cyan darken-4"
                            class="ml-4"
                            v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                            icon
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>

                        <span>Download PDF</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td :colspan="isVisitDetail ? 3 : 5" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no closer at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <template v-if="getPermission('closer:view')">
      <template v-if="closerDialog">
        <CloserDetail
          :closerDialog="closerDialog"
          :detail="detail"
          :closer="closer"
          :pageLoading="pageLoading"
          :customer="customer"
          :property="property"
          :billing="billing"
          :contactPerson="contactPerson"
          v-on:close-dialog="closerDialog = false"
        ></CloserDetail>
      </template>
    </template>
    <template v-if="getPermission('closer:create')">
      <template v-if="addcloserDialog">
        <CreateTimeCloser
          :isVisitDetail="isVisitDetail"
          :visitDetail="visitDetail"
          :closerDialog="addcloserDialog"
          :detail="detail"
          v-on:create:ticket-closer="createdTimeCloser"
          v-on:close-dialog="addcloserDialog = false"
        ></CreateTimeCloser>
      </template>
    </template>
    <Dialog :common-dialog="editDialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        Closer# {{ edit_closer?.barcode
        }}<v-icon small class="mx-4 color-custom-blue"
          >mdi-checkbox-blank-circle</v-icon
        >Visit# {{ edit_closer?.visit?.barcode }}
      </template>
      <template v-slot:body>
        <div class="mx-5">
          <v-row>
            <v-col md="12">
              <p class="font-size-16 font-weight-600 color-custom-blue my-0">
                Detail
              </p>
            </v-col>
            <v-col md="12" class="custom-border-top">
              <v-textarea
                v-model.trim="edit_closer.details"
                auto-grow
                dense
                filled
                :disabled="editLoading"
                :loading="editLoading"
                color="cyan"
                label="Detail"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-col>
            <v-col md="12" class="mt-5">
              <p class="font-size-16 font-weight-600 color-custom-blue my-0">
                Attachments
              </p>
            </v-col>
            <v-col md="12" class="custom-border-top">
              <v-row>
                <v-col md="2">
                  <div class="custom-border px-2 py-2">
                    <div
                      class="custom-border text-center py-10 cursor-pointer"
                      v-on:click="selectImage()"
                    >
                      <template v-if="imageLoading">
                        <v-progress-circular
                          indeterminate
                          color="cyan"
                        ></v-progress-circular>
                        <p class="my-0 font-size-18 font-weight-600 mt-2">
                          Please wait...
                        </p>
                      </template>
                      <template v-else>
                        <v-icon x-large>mdi-image</v-icon>
                        <p class="my-0 font-size-18 font-weight-600 mt-2">
                          Click here to select image
                        </p>
                      </template>
                    </div>
                  </div>
                  <div class="d-none">
                    <v-file-input
                      ref="uploadImage"
                      v-on:change="updateProfileImage"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/jpg"
                      multiple
                    ></v-file-input>
                  </div>
                </v-col>

                <v-col
                  md="2"
                  v-for="(row, index) in edit_attachments"
                  :key="`edit-image-${index}`"
                >
                  <div class="custom-border px-2 py-2">
                    <v-img
                      width="100%"
                      :src="row?.file?.url"
                      :alt="row?.file?.name"
                    />
                    <v-btn
                      :disabled="editLoading || imageLoading"
                      v-on:click="remove_image(index)"
                      icon
                      color="red lighten-1"
                      class="mt-2"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          color="cyan white--text"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="saveCloser()"
          :disabled="editLoading || imageLoading"
          :loading="editLoading || imageLoading"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="editDialog = false"
          :disabled="editLoading || imageLoading"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import CloserDetail from "@/view/pages/job/partials/Closer-Detail.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { PATCH } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin, FileManagerMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addcloserDialog: false,
      closerDialog: false,
      editDialog: false,
      closer: {},
      edit_closer: {},
      edit_attachments: [],
      ticketClosers: [],
      engineerList: [],
      dates: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      job: 0,
      closerLoading: false,
      imageLoading: false,
      editLoading: false,
    };
  },
  components: {
    Barcode,
    Dialog,
    CloserDetail,
    CreateTimeCloser,
  },
  watch: {
    visitDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getTicketCloser();
        this.getEngineerList();
        this.getVisitList();
      },
    },
  },
  methods: {
    saveCloser() {
      const formData = {
        detail: this.edit_closer.details,
        attachments: this.edit_attachments,
      };
      this.editLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: `job/${this.job}/visit/${this.edit_closer?.visit?.id}/closer/${this.edit_closer?.id}`,
          data: formData,
        })
        .then(() => {
          this.getTicketCloser();
        })
        .catch((error) => {
          console.log(error);
          this.editLoading = false;
        });
    },
    remove_image(index) {
      this.edit_attachments.splice(index, 1);
    },
    selectImage() {
      if (this.imageLoading) {
        return false;
      }
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      this.imageLoading = true;
      this.uploadAttachmentMultiple(param)
        .then((response) => {
          if (response?.length > 0) {
            for (let i = 0; i < response.length; i++) {
              this.edit_attachments.push(response[i]);
            }
          }
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.imageLoading = false;
        });
    },
    editRow(row) {
      this.edit_closer = { ...row };
      this.edit_attachments = row.attachments;
      this.editDialog = true;
    },
    editClose() {
      this.edit_closer = {};
      this.edit_attachments = [];
      this.editDialog = false;
    },
    downloadFile(url, prepend_url) {
      /* alert(23);
      console.log(url,"url"); */
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    detailTicketCloser(row) {
      this.closerDialog = true;
      this.closer = row;
    },
    createdTimeCloser() {
      this.addcloserDialog = false;
      this.getTicketCloser();
      DialogJobEventBus.$emit("load:job", true);
    },
    getTicketCloser() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.closerLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getClosers(_this.job, { filter })
          .then((response) => {
            _this.editClose();
            _this.ticketClosers = response;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.closerLoading = false;
            _this.editLoading = false;
          });
      }, _this.timeoutLimit);
    },
    getEngineerList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = rows.map((row) => {
              return row.engineer;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getVisitList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getTicketCloser();
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  mounted() {
    this.getTicketCloser();
    this.getEngineerList();
    this.getVisitList();
  },
};
</script>
