<template>
  <DetailTemplate
    :custom-class="'ticket-detail detail-page'"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center" v-if="pageLoaded">
        <span
          class="color-custom-blue font-weight-700 font-size-20 text-truncate"
          >{{ visit.title }}</span
        >
        <Priority :priority="detail.priority"></Priority>
        <CustomStatus
          :status="visit.status"
          endpoint="visit/status"
        ></CustomStatus>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mx-2 d-inline-table font-weight-600"
              color="chip-custom-blue"
              label
              outlined
            >
              {{ getScheduleType }}
            </v-chip>
          </template>
          <span>Schedule Type</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template v-if="false && visit.status < 4">
          <template v-if="!runningTimer.id">
            <v-btn
              v-if="visit.status == 3"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="resumeTimerDialog = true"
            >
              <v-icon left>mdi-clock-alert-outline</v-icon>
              Resume Timer
            </v-btn>
            <v-btn
              v-else
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="getServiceFormList()"
            >
              <v-icon left>mdi-clock-outline</v-icon>
              Start Timer
            </v-btn>
          </template>
          <template v-else>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  :disabled="timerLoading || visitLoading"
                  :loading="timerLoading"
                  v-on:click="assignServiceFormDetail"
                >
                  Service Form
                </v-btn>
              </template>
              <span>Click here to assign service form</span>
            </v-tooltip>
            <template
              v-if="!runningTimer.closer && runningTimer.is_service_form_filled"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="closerDialog = true"
                  >
                    Closer
                  </v-btn>
                </template>
                <span>Click here to create closer</span>
              </v-tooltip>
            </template>
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
            >
              {{ timerDuration.hour }}<span class="blink-me">:</span
              >{{ timerDuration.minute }}<span class="blink-me">:</span
              >{{ timerDuration.second }}
            </v-btn>
          </template>
        </template>
        <v-btn
          v-if="false && detail.type == 2 && visit.status == 1"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="reassign_dialog = true"
        >
          Reschedule
        </v-btn>
        <template v-if="visit.status == 1">
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :to="
              getDefaultRoute('job.create', {
                query: { ticket: ticketId, visit: visitId },
              })
            "
          >
            <v-icon left>mdi-pencil</v-icon> Edit
          </v-btn>
        </template>
        <template v-if="visit.status == 1">
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="appointmentUpdateDialog = true"
          >
            <v-icon left>mdi-calendar-refresh</v-icon> Reschedule
          </v-btn>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }" v-if="visit.status != 4">
            <v-btn
              :disabled="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <template v-if="engineerList.length">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assigned_team"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span>
                          </v-tooltip>
                          <v-list-item-subtitle
                            v-if="lodash.isEmpty(team.designation) == false"
                            >{{ team.designation.value }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: calc(100vh - 162px); position: relative"
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="5">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500 text-capitalize">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${visit?.property?.property_address}`"
                          >{{ visit?.property?.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.display_name
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.display_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.primary_phone
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_phone }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.primary_email
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_email }}
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <!-- <v-flex md6 class="ml-2">
                  <table width="100%" >
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.billing_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_email }}
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex> -->
              </v-layout>
            </v-col>
            <v-col md="7" class="custom-border-left gray-background">
              <table width="100%">
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ visit.title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Visit #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.barcode }}</label>
                  </td>
                </tr>
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.reference }}</label>
                  </td>
                </tr> -->

                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Appointment
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          v-bind="attrs"
                          v-on="on"
                          href="javascript:void(0)"
                          v-on:click="downloadVisit()"
                          class="text-uppercase max-content-width"
                          >{{ getAppointmentText }}</a
                        >
                      </template>
                      <span>Click here to download Appointment</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Amount to be Collected
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                      formatMoney(detail.amount_to_be_collected)
                    }}</label>
                  </td>
                </tr>
                <template v-if="visit && visit.ticket_ticket_closer">
                  <tr
                    v-for="(row, index) in visit.ticket_ticket_closer"
                    :key="index"
                  >
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Amount Collected
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label class="m-0">{{
                        row.amount_to_be_collected
                      }}</label>
                    </td>
                  </tr>
                </template>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Remark
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.remark }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Schedule Type
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500 text-capitalize"
                  >
                    <label class="m-0">{{
                      lodash.lowerCase(detail.btx_schedule_tab)
                    }}</label>
                  </td>
                </tr>
                <tr v-if="visit.schedule_message">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Schedule
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.schedule_message }}</label>
                  </td>
                </tr>
              </table>
              <v-tooltip
                v-for="(type, index) in detail.ticket_type"
                :key="'ticket-type' + index"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2 font-weight-600"
                    color="chip-custom-blue"
                    label
                    small
                    outlined
                  >
                    {{ type }}
                  </v-chip>
                </template>
                <span>Job Type</span>
              </v-tooltip>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="ticketTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#team">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Team
                </v-tab>
                <!-- <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#service-form"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    
                    <inline-svg
                      :src="$assetURL('media/custom-svg/service-form.svg')"
                    />
                   
                  </span>
                  Service Form
                </v-tab> -->
                <v-tab class="font-size-16 font-weight-600 px-8" href="#closer">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/closer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Closer
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#notes">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/note.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Notes
                </v-tab>
                <!-- <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                   
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    
                  </span>
                  Invoices
                </v-tab> -->
                <!-- <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#quotation"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                   
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                   
                  </span>
                  Quotation
                </v-tab> -->
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="ticketTab">
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >INTERNAL NOTES &amp; ATTACHMENTS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-layout class="mb-4">
                          <v-flex md6 class="custom-border-right">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.client_remark ||
                                '<em>No Client Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                          <v-flex md6>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark || '<em>No Admin Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                        </v-layout>
                        <v-layout
                          v-if="detail.documents.length"
                          class="custom-border-top"
                        >
                          <v-flex md12>
                            <FileTemplate
                              :attachments="detail.documents"
                            ></FileTemplate>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <TicketLineItem
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:load:content="getVisit()"
                  ></TicketLineItem>
                </v-tab-item>
                <v-tab-item value="team">
                  <VisitTeamDetail
                    :detail="{ id: ticketId }"
                    :visit-id="visitId"
                    v-on:delete:engineer="getVisit()"
                  ></VisitTeamDetail>
                </v-tab-item>
                <v-tab-item value="service-form">
                  <ServiceFormDetail
                    :detail="detail"
                    :visit="visit"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                    is-visit-detail
                  ></ServiceFormDetail>
                </v-tab-item>
                <v-tab-item value="closer">
                  <TicketCloserDetail
                    is-visit-detail
                    :visit-detail="visit"
                    :detail="detail"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                  ></TicketCloserDetail>
                </v-tab-item>
                <v-tab-item value="notes">
                  <VisitNotesDetail
                    :detail="detail"
                    :visit="visit"
                    :notes="visit.notes"
                    :page-loading="visitLoading"
                  ></VisitNotesDetail>
                </v-tab-item>
                <v-tab-item value="invoice">
                  <InternalInvoice
                    type="visit"
                    :type-id="visitId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="quotation">
                  <InternalQuotation
                    type="visit"
                    :type-id="visitId"
                  ></InternalQuotation>
                </v-tab-item>
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="visit"
                     :type_id="visit.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <Dialog :common-dialog="startTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    Are you sure, you want to start timer for visit#
                    {{ visit.barcode }} ?
                  </p>
                </v-col>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimerWithLater"
          >
            Assign Later
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="startTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="assignServiceFormDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="assignServiceForm"
            v-model.trim="assignFormValid"
            lazy-validation
            v-on:submit.stop.prevent="assignServiceForm"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="assignFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="assignFormLoading || !assignFormValid || visitLoading"
            :loading="assignFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="assignServiceForm"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="assignFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="assignServiceFormDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="resumeTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue my-0">
                  Are you sure, you want to resume timer for visit#
                  {{ visit.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            :loading="timerLoading || timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="resumeTimer()"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="resumeTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 25vh">
            <v-row class="custom-grey-border">
              <v-col md="4">
                <label class="ml-2" for="visit-one-off-start-date"
                  >Start Date</label
                >
                <DatePicker
                  id="visit-schedule-start-date"
                  placeholder="Start Date"
                  v-model="rescheduleObj.start_date"
                  v-on:change="($event) => (rescheduleObj.end_date = $event)"
                />
              </v-col>
              <v-col md="4">
                <label class="ml-2" for="visit-one-off-start-time"
                  >Start Time</label
                >
                <TimePicker
                  id="visit-schedule-start-time"
                  placeholder="Start Time"
                  v-model="rescheduleObj.start_time"
                />
              </v-col>
              <v-col md="4">
                <label class="ml-2" for="visit-one-off-end-time"
                  >End Time</label
                >
                <TimePicker
                  id="visit-schedule-end-time"
                  placeholder="End Time"
                  :min-time="rescheduleObj.start_time"
                  v-model="rescheduleObj.end_time"
                />
              </v-col>
              <v-col md="12">
                <p
                  class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                >
                  Updated Schedule - This job will start on
                  {{ formatDate(rescheduleObj.start_date) }}
                  {{ rescheduleObj.start_time }} and finish on
                  {{ formatDate(rescheduleObj.end_date) }}
                  {{ rescheduleObj.end_time }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <CreateCloser
        v-if="runningTimer.id"
        is-visit-detail
        :ticket-id="ticketId"
        :visit-id="visitId"
        :timesheet-id="runningTimer.id"
        :timesheet="runningTimer.id"
        :visit-detail="visit"
        :closer-dialog="closerDialog"
        :detail="detail"
        v-on:create:ticket-closer="createdTimeCloser"
        v-on:close-dialog="closerDialog = false"
      ></CreateCloser>
      <RecurringReassignSchedule
        :reassign-schedule-dialog="reassign_dialog"
        v-on:close="reassign_dialog = false"
        v-on:success="getVisit()"
        :job-id="ticketId"
        :show-calendar="false"
      ></RecurringReassignSchedule>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import TicketLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import ValidationMixin from "@/core/plugins/validation-mixin";
import VisitTeamDetail from "@/view/pages/job/partials/Visit-Team-Detail.vue";
import RecurringReassignSchedule from "@/view/pages/partials/Recurring-Reassign-schedule.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import TimePicker from "@/view/components/TimePicker.vue";

import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import VisitNotesDetail from "@/view/pages/job/partials/Visit-Notes-Detail.vue";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import Dialog from "@/view/pages/partials/Dialog";
import InternalQuotation from "@/view/pages/partials/Internal-Quotation";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import {
  GET,
  QUERY,
  PATCH,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import CreateCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import JwtService from "@/core/services/jwt.service";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  toSafeInteger,
  filter,
  head,
  last,
  isEmpty,
  concat,
  toNumber,
  toString,
  padStart,
} from "lodash";
import { downloadEvent } from "@/core/lib/ics.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      yourFormat: "hh:mm A",
      visitId: 0,
      ticketId: 0,
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      endTimereschdule: null,
      starTimereschdule: null,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      visitLoading: false,
      serviceFormLoading: false,
      reassign_dialog: false,
      reschedule_dialog: false,
      appointmentUpdateDialog: false,
      timerFormLoading: false,
      serviceFormList: [],
      rescheduleObj: {
        start_date: null,
        start_time: null,
        end_time: null,
        end_date: null,
      },
      startTimerDialog: false,
      timerLoading: false,
      runningTimer: {
        barcode: null,
        closer: null,
        date: null,
        duration: null,
        end_at: null,
        end_date: null,
        id: null,
        is_service_form_assigned: false,
        is_service_form_filled: false,
        service_form: null,
        start_at: null,
        status: null,
        status_text: null,
        ticket: null,
        visit: null,
      },
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      isTimmerRunning: false,
      resumeTimerDialog: false,
      closerDialog: false,
      assignFormLoading: false,
      assignServiceFormDialog: false,
      timerFormValid: true,
      assignFormValid: true,
      timeInterval: null,
      durationInterval: null,
      timeIntervalLimit: 10000,
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_time: null,
      },
      apptFormLoading: false,
      apptEngLoading: false,
      engineer_menu: false,
      ticketTab: "other",
      timerServiceForm: [],
      moreActions: [],
      detail: {},
      visit: {},
      dbLineItems: [],
      dbEquipments: [],
      engineerList: [],
      assigned_team: [],
      form_type: null,
    };
  },
  methods: {
    gobackListpage() {
      this.$router.push(
        this.getDefaultRoute("visit", {
          query: {
            status: "all",
            tab: this.$route.query.tab,
            page: this.$route.query.page,
          },
        })
      );
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                to_equipment: data[i].to_equipment,
                product_type: data[i].product_type,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                service_option: data[i].service_option,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getDurationText() {
      const start_date = head(this.detail.duration);
      const last_date = last(this.detail.duration);
      return `${this.formatDate(start_date)} to ${this.formatDate(last_date)}`;
    },
    getServiceFormList() {
      if (this.serviceFormLoading) {
        return false;
      }
      this.serviceFormLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: { customer: this.visit.customer, form_type: this.form_type },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.startTimerDialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.serviceFormLoading = false;
        });
    },
    startTimer() {
      if (!this.$refs.timerStartForm.validate()) {
        return false;
      }

      this.timerFormLoading = true;
      this.timerLoading = true;

      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/timer/start",
          data: { service_form: this.timerServiceForm },
        })
        .then(() => {
          this.startTimerDialog = false;
          this.timerServiceForm = new Array();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.timerLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },
    startTimerWithLater() {
      if (this.isTimmerRunning) {
        this.closerDialog = true;
      } else {
        this.timerFormLoading = true;
        this.timerLoading = true;
        this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              this.ticketId +
              "/visit/" +
              this.visitId +
              "/timer/start",
          })
          .then(() => {
            this.startTimerDialog = false;
            this.timerServiceForm = new Array();
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerFormLoading = false;
            this.timerLoading = false;
            this.getVisit();
            this.checkTimerStatus();
            this.updateTimerDuration();
          });
      }
    },
    getActiveTimer() {
      if (this.visit.status == 2) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running",
          })
          .then(({ data }) => {
            this.runningTimer = new Object(data);
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
        _this.getActiveTimer,
        _this.timeIntervalLimit
      );
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    setTimerDuration() {
      if (isEmpty(this.runningTimer) === false) {
        let startDate = toString(this.runningTimer.date);
        if (this.runningTimer.start_at) {
          startDate = toString(startDate + " " + this.runningTimer.start_at);
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          this.timerDuration = {
            hour: hours > 0 ? padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    assignServiceFormDetail() {
      this.timerLoading = true;

      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: { timesheet: this.runningTimer.id },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.assignServiceFormDialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    assignServiceForm() {
      if (!this.$refs.assignServiceForm.validate()) {
        return false;
      }

      this.assignFormLoading = true;

      this.$store
        .dispatch(POST, {
          url: `job/${this.ticketId}/visit/${this.visitId}/service-form/${this.timerServiceForm}`,
          data: { timesheet: this.runningTimer.id || null },
        })
        .then(() => {
          this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.assignFormLoading = false;
          this.assignServiceFormDialog = false;
          this.getActiveTimer();
        });
    },
    createdTimeCloser() {
      this.serviceFormLoading = false;
      this.timerFormLoading = false;
      this.serviceFormList = [];
      this.startTimerDialog = false;
      this.timerLoading = false;
      this.runningTimer = {
        barcode: null,
        closer: null,
        date: null,
        duration: null,
        end_at: null,
        end_date: null,
        id: null,
        is_service_form_assigned: false,
        is_service_form_filled: false,
        service_form: null,
        start_at: null,
        status: null,
        status_text: null,
        ticket: null,
        visit: null,
      };
      this.timerDuration = {
        hour: "00",
        minute: "00",
        second: "00",
      };
      this.isTimmerRunning = false;
      this.resumeTimerDialog = false;
      this.closerDialog = false;
      this.assignFormLoading = false;
      this.assignServiceFormDialog = false;
      this.timerFormValid = true;
      this.assignFormValid = true;
      this.getVisit();
      clearInterval(this.timeInterval);
      clearInterval(this.durationInterval);
    },
    resumeTimer() {
      this.timerFormLoading = true;
      this.$store
        .dispatch(PATCH, { url: "visit/" + this.visitId + "/resume" })
        .then(() => {
          this.resumeTimerDialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentStartTime(param) {
      this.updateAppointmentFormData.start_time = param;
    },
    updateAppointmentEndTime(param) {
      this.updateAppointmentFormData.end_time = param;
    },
    updateAppointment() {
      this.apptFormLoading = true;

      this.$store
        /* .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/re-schedule",
          data: {
            date: this.updateAppointmentFormData.date
              ? moment(this.updateAppointmentFormData.date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            start_time: this.updateAppointmentFormData.start_time
              ? moment(this.updateAppointmentFormData.start_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: this.updateAppointmentFormData.end_time
              ? moment(this.updateAppointmentFormData.end_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        }) */
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/re-schedule",
          data: {
            date: this.rescheduleObj.start_date
              ? moment(this.rescheduleObj.start_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: this.rescheduleObj.end_date
              ? moment(this.rescheduleObj.end_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),

            start_time: this.rescheduleObj.start_time
              ? moment(this.rescheduleObj.start_time, ["hh:mm a"]).format(
                  "HH:mm"
                )
              : moment().startOf("day").format("HH:mm"),
            end_time: this.rescheduleObj.end_time
              ? moment(this.rescheduleObj.end_time, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        })
        .then(() => {
          this.appointmentUpdateDialog = false;
          this.$router.go();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptFormLoading = false;
        });
    },
    getAvailableEngineers() {
      this.loadingEngineers = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(this.visit.started_at).format("HH:mm"),
            end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
            end_time: moment(this.visit.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
          },
        })
        .then(({ data }) => {
          this.engineerList = data.engineers;
          this.engineer_menu = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            "duplicate-ticket": this.ticketId,
            "duplicate-visit": this.visitId,
          },
        })
      );
    },

    updateEngineer() {
      if (!this.assigned_team.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      }
      this.apptEngLoading = true;
      this.$store
        .dispatch(PUT, {
          url: "job/" + this.ticketId + "/visit/" + this.visitId + "/engineer",
          data: {
            engineer: this.assigned_team,
          },
        })
        .then(() => {
          this.engineerList = [];
          this.$nextTick(() => {
            this.getVisit();
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptEngLoading = false;
        });
    },
    updateMoreAction(type) {
      switch (type) {
        case "duplicate":
          this.routeToDuplicate();
          break;

        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "re_schedule_visit":
          this.appointmentUpdateDialog = true;
          break;
        case "mark_as_cancel":
          this.updateStatus("mark_as_cancel");
          break;
        case "available_staff":
          this.getAvailableEngineers();
          break;
        case "download_schedule":
          this.downloadSchedule();
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visit.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visit.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visit.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visit.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: concat(startDateArray, startTimeArray),
          end: concat(endDateArray, endTimeArray),
          title: this.visit.title,
          description: this.visit.instruction,
          location: this.detail.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.detail.property.latitude),
            lon: toNumber(this.detail.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.visit.added_by.display_name,
            email: this.visit.added_by.user_email,
          },
          attendees: this.visit.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({ error });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "visit/" + this.visitId + "/status",
          data: { status },
        })
        .then(() => {
          this.getVisit();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    downloadSchedule() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${id}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    downloadPDF() {
      window.open(this.visit.pdf_url, "_blank");
    },
    printPDF() {
      window.open(this.visit.pdf_url, "_blank");
    },
    routeToQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    getVisit() {
      this.$store
        .dispatch(GET, { url: "visit/v1/" + this.visitId })
        .then(({ data }) => {
          this.detail = data.ticket;
          this.visit = data.visit;

          this.rescheduleObj = {
            start_date: moment(data.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(data.visit.started_at).format("hh:mm A"),
            end_time: moment(data.visit.finished_at).format("hh:mm A"),
            end_date: moment(data.visit.finished_at).format("YYYY-MM-DD"),
          };

          this.starTimereschdule = moment(data.visit.started_at).format(
            "hh:mm A"
          );
          this.endTimereschdule = moment(data.visit.finished_at).format(
            "hh:mm A"
          );
          this.ticketId = toSafeInteger(this.detail.id);
          this.visitId = toSafeInteger(this.visit.id);

          this.moreActions = data.visit.more_actions;

          if (this.visit.engineers && this.visit.engineers.length) {
            this.assigned_team = this.visit.engineers.map(
              (row) => row.engineer
            );
          } else {
            this.assigned_team = [];
          }

          if (this.detail.type == 1) {
            this.form_type = "Corrective";
          } else if (this.detail.type == 2) {
            this.form_type = "Preventive";
          }

          this.$nextTick(() => {
            this.getLineItems({
              job: this.ticketId,
              visit: this.visitId,
            });
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Detail" },
            { title: this.visit.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.getActiveTimer();
        });
    },
  },
  created() {
    this.visitId = toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.getVisit();
    this.checkTimerStatus();
    this.updateTimerDuration();

    ServiceFormEventBus.$on("save:service-form", () => {
      this.getActiveTimer();
    });
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    getAppointmentText() {
      if (isEmpty(this.visit) === false) {
        let date = this.formatDate(this.visit.started_at);
        let startTime = moment(this.visit.started_at).format("hh:mm A");
        let endTime = moment(this.visit.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
    updateStartDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("hh:mm A");
      }
      return null;
    },
    getScheduleType() {
      if (this.detail.type == 1) {
        return "One-Off Job";
      }
      if (this.detail.type == 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
  components: {
    Dialog,
    Priority,
    CreateCloser,
    CustomStatus,
    FileTemplate,
    DetailTemplate,
    InternalInvoice,
    InternalQuotation,
    TicketLineItem,
    VisitTeamDetail,
    ServiceFormDetail,
    DatePicker,
    TimePicker,
    RecurringReassignSchedule,
    TicketCloserDetail,
    VisitNotesDetail,
    InternalHistoryDetail,
  },
};
</script>
