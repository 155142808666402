<template>
  <v-container class="recurring-pattern-template px-0 py-0">
    <v-select
      v-model="newpattern"
      :items="durationList"
      item-text="text"
      item-value="value"
      color="cyan"
      item-color="cyan"
      v-on:change="updateDurationList"
    />

    <v-tabs
      height="32"
      color="cyan"
      v-model.trim="pattern.type"
      background-color="transparent"
    >
      <!-- <v-tab href="#daily" class="font-size-14 font-weight-500">Daily</v-tab>
      <v-tab href="#weekly" class="font-size-14 font-weight-500">Weekly</v-tab>
      <v-tab href="#monthly" class="font-size-14 font-weight-500"
        >Monthly</v-tab
      > -->
      <v-tabs-items v-model.trim="pattern.type">
        <v-tab-item :value="'daily'" class="mt-4">
          <!-- <v-radio-group
            mandatory
            class="mt-0"
            v-model.trim="pattern.daily.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'daily'"
          >
            <v-radio value="2" color="cyan">
              <template v-slot:label>
                <table >
                  <tr>
                    <td>Every</td>
                    <td>
                      <v-text-field
                        dense
                        filled
                        flat
                        v-mask="'###'"
                        hide-details
                        solo
                        :rules="
                          pattern.daily.recurring_pattern != 2
                            ? []
                            : [
                                validateRules.required(
                                  pattern.daily.day_count,
                                  'Day'
                                ),
                              ]
                        "
                        color="cyan"
                        :loading="loading"
                        :disabled="
                          loading || pattern.daily.recurring_pattern != 2
                        "
                        v-model.trim="pattern.daily.day_count"
                        class="mx-2 min-width-100px max-width-100px"
                      ></v-text-field>
                    </td>
                    <td>day(s)</td>
                  </tr>
                </table>
              </template>
            </v-radio>
            <v-radio label="Weekday" color="cyan" value="1"></v-radio>
          </v-radio-group> -->
        </v-tab-item>
        <v-tab-item :value="'weekly'" class="mt-4">
          <v-radio-group
            mandatory
            class="mt-0"
            v-model.trim="pattern.weekly.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'weekly'"
          >
            <!-- <v-radio value="1" color="cyan">
              <template v-slot:label>
                <table>
                  <tr>
                    <td>Every</td>
                    <td>
                      <v-text-field
                        dense
                        filled
                        flat
                        v-mask="'###'"
                        hide-details
                        solo
                        color="cyan"
                        :rules="[
                          validateRules.required(
                            pattern.weekly.week_count,
                            'Count'
                          ),
                        ]"
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="pattern.weekly.week_count"
                        class="mx-2 min-width-100px max-width-100px"
                      ></v-text-field>
                    </td>
                    <td>week(s) on</td>
                  </tr>
                </table>
              </template>
            </v-radio> -->
          </v-radio-group>
          <v-row>
            <!-- <pre>{{jobWeekDays}}</pre> -->
            <v-col
              :md="showCalendar ? 3 : 2"
              v-for="(weekday, index) in jobWeekDays"
              :key="index"
            >
              <v-checkbox
                color="cyan"
                :rules="[validateRules.required(pattern.weekly.weeks, 'Weeks')]"
                v-model.trim="pattern.weekly.weeks"
                :label="showCalendar ? weekday.short_name : weekday.long_name"
                :value="weekday.id"
                hide-details
                class="mt-0"
                :disabled="is_disabled(weekday)"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'monthly'" class="mt-4">
          <v-radio-group
            v-if="newpattern != 'bi_annually' && newpattern != 'annually'"
            mandatory
            class="mt-0"
            v-model.trim="pattern.monthly.recurring_pattern"
            hide-details
            :disabled="loading || pattern.type != 'monthly'"
          >
            <v-radio
              value="1"
              color="cyan"
              class="align-items-start radio-icon-start"
            >
              <template v-slot:label>
                <div class="d-flex flex-wrap align-center">
                  <span>Day</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 1
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.day_count,
                                'Day'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 1
                      "
                      v-model.trim="pattern.monthly.day_count"
                      class="mx-2 min-width-100px max-width-100px"
                    ></v-text-field>
                  </span>
                  <span>of every</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 1
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.day_month_count,
                                'Month'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 1
                      "
                      v-model.trim="pattern.monthly.day_month_count"
                      class="mx-2 min-width-100px max-width-100px"
                    ></v-text-field>
                  </span>
                  <span>month(s)</span>
                </div>
                <!-- </perfect-scrollbar> -->
              </template>
            </v-radio>
            <v-radio
              value="2"
              color="cyan"
              class="align-items-start radio-icon-start"
            >
              <template v-slot:label>
                <!-- <perfect-scrollbar
                  :options="{ suppressScrollY: true, useBothWheelAxes: true }"
                  class="scroll position-relative"
                  :style="verticalScrollbar"
                > -->
                <div class="d-flex flex-wrap align-center">
                  <span>The</span>
                  <span class="mb-1">
                    <v-select
                      :items="jobDayOfMonths"
                      dense
                      filled
                      flat
                      item-text="value"
                      item-value="id"
                      label="Day of Month"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.week_day,
                                'Week'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.week_day"
                      class="mx-2 width-150px"
                    >
                    </v-select>
                  </span>
                  <span class="mb-1">
                    <v-select
                      :items="jobWeekDays"
                      dense
                      filled
                      flat
                      item-text="long_name"
                      item-value="id"
                      label="Weekday"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.week_count,
                                'Count'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.week_count"
                      class="mx-2 width-150px"
                    >
                    </v-select>
                  </span>
                  <span>of every</span>
                  <span class="mb-1">
                    <v-text-field
                      dense
                      filled
                      flat
                      v-mask="'###'"
                      hide-details
                      solo
                      :rules="
                        pattern.monthly.recurring_pattern != 2
                          ? []
                          : [
                              validateRules.required(
                                pattern.monthly.month_count,
                                'Month'
                              ),
                            ]
                      "
                      color="cyan"
                      :loading="loading"
                      :disabled="
                        loading || pattern.monthly.recurring_pattern != 2
                      "
                      v-model.trim="pattern.monthly.month_count"
                      class="mx-2 width-100px"
                    ></v-text-field>
                  </span>
                  <span>month(s)</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationMixin from "@/core/plugins/validation-mixin";
import JobRecurringPatternMixin from "@/core/lib/job/job.recurring.pattern.mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { WeekEventBus } from "@/core/lib/message.lib";
import { ProductFormEventBus } from "@/core/lib/product.from.lib.js";

export default {
  name: "recurring-pattern-template",
  mixins: [JobRecurringPatternMixin, ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newpattern: "daily",
      durationList: [
        { text: "daily", value: "daily" },
        { text: "weekly", value: "weekly" },
        { text: "twice a week", value: "twice_a_week" },
        { text: "fort-nightly", value: "fort_nightly" },
        { text: "thrice a week", value: "thrice_a_week" },
        { text: "monthly", value: "monthly" },
        { text: "twice a month", value: "twice_a_month" },
        { text: "bi-monthly", value: "bi_monthly" },
        { text: "quarterly", value: "quarterly" },
        { text: "bi-annually", value: "bi_annually" },
        { text: "annually", value: "annually" },
      ],

      timeout: null,
      timeoutLimit: 500,

      pattern: {
        type: "daily",
        newPattern: "daily",
        daily: {
          day_count: "1",
          recurring_pattern: "2",
        },
        weekly: {
          recurring_pattern: "1",
          week_count: "1",
          weeks: [1],
        },
        monthly: {
          recurring_pattern: "1",
          day_count: "1",
          week_day: 1,
          week_count: 1,
          day_month_count: "1",
          month_count: "1",
        },
      },
    };
  },
  watch: {
    "pattern.type"(param) {
      const _this = this;
      if (param.toString() === "daily") {
        _this.pattern.daily.day_count = "1";
        _this.pattern.daily.recurring_pattern = "2";
      }
      if (param.toString() === "weekly") {
        _this.pattern.weekly.week_count = "1";
        _this.pattern.weekly.weeks = [1];
        _this.pattern.weekly.recurring_pattern = "2";
      }
      if (param.toString() === "monthly") {
        _this.pattern.monthly.recurring_pattern = "1";
        _this.pattern.monthly.day_count = "1";
        _this.pattern.monthly.week_day = 1;
        _this.pattern.monthly.week_count = 1;
        _this.pattern.monthly.day_month_count = "1";
        _this.pattern.monthly.month_count = "1";
      }

      if (
        this.newpattern === "monthly" ||
        this.newpattern === "bi_monthly" ||
        this.newpattern === "quarterly" ||
        this.newpattern === "bi_annually" ||
        this.newpattern === "annually"
      ) {
        // WeekEventBus.$emit("weekDialogDetable", false);
      }

      ProductFormEventBus.$emit("reload:recrence", true);
      this.updateDurationList();
      /* console.log(this.newPattern,"pattern");
      this.pattern.newPattern = this.newpattern; */
    },

    /* "pattern.weekly.weeks"(param) {
      if (this.newpattern === "twice_a_week") {
        if (param.length === 1) {
          WeekEventBus.$emit("weekDialogDetable", true);
        }
        if (param.length === 2) {
          WeekEventBus.$emit("weekDialogDetable", false);
        }
      }
      if (this.newpattern === "weekly") {
        if (param.length === 1) {
          WeekEventBus.$emit("weekDialogDetable", false);
        }
      }

      if (this.newpattern === "fort_nightly") {
        if (param.length === 1) {
          WeekEventBus.$emit("weekDialogDetable", false);
        }
      }

      if (this.newpattern === "thrice_a_week") {
        if (param.length === 1 || param.length === 2) {
          WeekEventBus.$emit("weekDialogDetable", true);
        }
        if (param.length === 3) {
          WeekEventBus.$emit("weekDialogDetable", false);
        }
      }
    }, */

    /*  "pattern.type"(param) {
 console.log(param);
     if (this.newpattern === "monthly"  ) {
        WeekEventBus.$emit(
          "weekDialogDetable",
          false
        );
     } 
     
} */

    pattern: {
      deep: true,
      immediate: true,
      handler(param) {
        const pattern = this.lodash.cloneDeep(param);

        if (pattern.type == "daily") {
          if (pattern.daily.recurring_pattern == 1) {
            pattern.daily.day_count = 0;
          }
        }

        this.$nextTick(() => {
          this.emitEvent();
        });
      },
    },

    getRecurringSchedule: {
      immediate: true,
      handler(param) {
        this.initUpdate(param);
      },
    },
  },
  methods: {
    initUpdate(param) {
      if (!param?.pattern) {
        return false;
      }

      this.newpattern = param?.pattern?.newPattern;

      this.pattern = {
        type: param?.pattern?.type ?? "daily",
        newPattern: param?.pattern?.newPattern ?? "daily",
        daily: {
          day_count: param?.pattern?.daily?.day_count ?? "1",
          recurring_pattern: param?.pattern?.daily?.recurring_pattern ?? "2",
        },
        weekly: {
          recurring_pattern: param?.pattern?.weekly?.recurring_pattern ?? "1",
          week_count: param?.pattern?.weekly?.week_count ?? "1",
          weeks: param?.pattern?.weekly?.weeks ?? [1],
        },
        monthly: {
          recurring_pattern: param?.pattern?.monthly?.recurring_pattern ?? "1",
          day_count: param?.pattern?.monthly?.day_count ?? "1",
          week_day: param?.pattern?.monthly?.week_day ?? 1,
          week_count: param?.pattern?.monthly?.week_count ?? 1,
          day_month_count: param?.pattern?.monthly?.day_month_count ?? "1",
          month_count: param?.pattern?.monthly?.month_count ?? "1",
        },
      };
    },
    is_disabled({ id }) {
      const new_patt = this.newpattern;
      const weeks = this.pattern.weekly.weeks;

      if (new_patt == "twice_a_week") {
        if (weeks.length >= 2) {
          if (weeks.includes(id)) {
            return false;
          }
          return true;
        }
      }
      if (new_patt == "thrice_a_week") {
        if (weeks.length >= 3) {
          if (weeks.includes(id)) {
            return false;
          }
          return true;
        }
      }
      if (new_patt == "fort_nightly") {
        if (weeks.length >= 1) {
          if (weeks.includes(id)) {
            return false;
          }
          return true;
        }
      }
      if (new_patt == "weekly") {
        if (weeks.length >= 1) {
          if (weeks.includes(id)) {
            return false;
          }
          return true;
        }
      }
    },
    updateDurationList() {
      if (
        this.newpattern === "weekly" ||
        this.newpattern === "twice_a_week" ||
        this.newpattern === "thrice_a_week" ||
        this.newpattern === "fort_nightly"
      ) {
        this.pattern.type = "weekly";
      }
      if (this.newpattern === "weekly") {
        this.pattern.weekly.weeks = [1];
        this.pattern.weekly.week_count = 1;
      }
      if (this.newpattern === "twice_a_week") {
        this.pattern.weekly.weeks = [1, 3];
        this.pattern.weekly.week_count = 1;
      }
      if (this.newpattern === "thrice_a_week") {
        this.pattern.weekly.weeks = [1, 3, 5];
        this.pattern.weekly.week_count = 1;
      }
      if (this.newpattern === "fort_nightly") {
        this.pattern.weekly.weeks = [1];
        this.pattern.weekly.week_count = 2;
      }
      if (
        this.newpattern === "monthly" ||
        this.newpattern === "bi_monthly" ||
        this.newpattern === "quarterly" ||
        this.newpattern === "bi_annually" ||
        this.newpattern === "annually"
      ) {
        this.pattern.type = "monthly";
      }

      if (this.newpattern === "monthly") {
        this.pattern.monthly.day_month_count = "1";
      }
      if (this.newpattern === "bi_monthly") {
        this.pattern.monthly.day_month_count = "2";
      }
      if (this.newpattern === "quarterly") {
        this.pattern.monthly.day_month_count = "4";
      }
      if (this.newpattern === "bi_annually") {
        this.pattern.monthly.day_month_count = "6";
      }
      if (this.newpattern === "annually") {
        this.pattern.monthly.day_month_count = "12";
      }
      if (this.newpattern === "daily") {
        this.pattern.type = "daily";
      }
      this.pattern.newpattern = this.newpattern;
      ProductFormEventBus.$emit("reload:recrence", true);
      WeekEventBus.$emit("visit-pattern-change", {
        pattern: this.newpattern,
        param: this.pattern,
      });
    },

    emitEvent() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        JobEventBus.$emit("update:recurring-pattern", _this.pattern);
      }, _this.timeoutLimit);
    },
  },
  computed: {
    ...mapGetters(["getRecurringSchedule"]),
    verticalScrollbar() {
      return "max-width: " + (this.showCalendar ? "60vh;" : "100%;");
    },
  },
};
</script>
