<template>
  <div class="recurring-schedule-body">
    <Dialog :common-dialog="reassignScheduleDialog" :dialog-width="dialogWidth">
      <template v-slot:title> Reacurring Assign & Re-Schedule </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-form
            ref="mail_form"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="submit()"
          >
            <v-row :class="{ 'd-block': showCalendar }">
              <v-col md="6">
                <label class="font-weight-700 font-size-16"
                  >Schedule Type</label
                >
                <RecurringPatternTemplate
                  :loading="recurringLoading"
                  :showCalendar="showCalendar"
                >
                </RecurringPatternTemplate>
              </v-col>
              <v-col md="6">
                <label class="font-weight-700 font-size-16"
                  >Select Technician</label
                >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="job-type"
                  :items="engineerList"
                  :disabled="formLoading"
                  clearable
                  :loading="formLoading"
                  label="Select Technician"
                  solo
                  flat
                  item-color="cyan"
                  placeholder="Select Technician"
                  item-text="display_name"
                  v-model.trim="reassignEngineer.technician"
                  item-value="id"
                  hide-details
                  multiple
                  class="width-100"
                >
                  <template v-slot:item="data">
                    <table>
                      <tr>
                        <td>
                          <span class="mr-2">{{ data.item.display_name }}</span>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- <v-col md="4">
                                <label class="font-weight-800 font-size-16"
                                    style="color: red !important;">All exiting staff will be unassign</label>
                                <v-radio-group v-model="reassignSchedule.activated" row hide-details class="mt-0">
                                    <v-radio style="margin-right: -2px" :value="1" color="cyan"></v-radio>
                                    <v-chip label color="cyan" outlined class="text-white p-3 mr-5" small>Merge selected
                                        staff with existing staff</v-chip>
                                    <v-radio :value="0" color="red" style="margin-right: -2px"></v-radio>
                                    <v-chip label color="red" outlined class="text-white p-3" small>Replace selected staff
                                        with existing staff</v-chip>
                                </v-radio-group>
                            </v-col> -->
              <v-col :md="showCalendar ? 12 : 4">
                <v-row>
                  <v-col md="12">
                    <label class="font-weight-700 font-size-16"
                      >Start Date</label
                    >
                    <v-menu
                      v-model="startDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Start Date"
                          hide-details
                          solo
                          :rules="[
                            validateRules.required(
                              schedule.start_date,
                              'Start Date'
                            ),
                          ]"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :disabled="recurringLoading"
                          :loading="recurringLoading"
                          :value="formattedStartDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model.trim="schedule.start_date"
                        color="cyan"
                        v-on:input="startDatePicker = false"
                        v-on:change="updateMonthlyDayCount($event)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col :md="showCalendar ? 12 : 8">
                <v-row>
                  <v-col md="6">
                    <label
                      class="font-weight-700 font-size-16"
                      for="recurring-start-time"
                      >Start Time</label
                    >
                    <div>
                      <vue-timepicker
                        :format="yourFormat"
                        :minute-interval="15"
                        id="one-off-start-time"
                        class="b-timepicker"
                        input-width="100%"
                        now-button
                        placeholder="Start Time"
                        dense
                        outline
                        tile
                        reset-button
                        locale="en"
                        v-model="schedule.start_time"
                        v-on:change="update_time($event, schedule.start_time)"
                      >
                      </vue-timepicker>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <label
                      class="font-weight-700 font-size-16"
                      for="recurring-end-time"
                      >End Time</label
                    >
                    <div>
                      <vue-timepicker
                        :format="yourFormat"
                        :minute-interval="15"
                        id="one-off-end-time"
                        class="b-timepicker"
                        input-width="100%"
                        now-button
                        placeholder="End Time"
                        dense
                        outline
                        tile
                        reset-button
                        locale="en"
                        v-model="schedule.end_time"
                        v-on:change="update_end_time($event, schedule.end_time)"
                      >
                      </vue-timepicker>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="12">
                <v-container class="px-0">
                  <!-- <label class="font-weight-700 font-size-16"
                >End of Recurrence</label
              > -->
                  <v-radio-group
                    class="mt-0"
                    hide-details
                    mandatory
                    :disabled="recurringLoading"
                    v-model.trim="schedule.end_mode"
                  >
                    <v-radio v-if="false" :value="1" hide-details color="cyan">
                      <template v-slot:label>
                        <table>
                          <tr>
                            <td>No end date</td>
                          </tr>
                        </table>
                      </template>
                    </v-radio>
                    <v-radio :value="2" hide-details color="cyan">
                      <template v-slot:label>
                        <table>
                          <tr>
                            <td>End after</td>
                            <td>
                              <v-text-field
                                dense
                                filled
                                flat
                                v-mask="'###'"
                                hide-details
                                solo
                                :rules="
                                  schedule.end_mode != 2
                                    ? []
                                    : [
                                        validateRules.required(
                                          schedule.occurrence,
                                          'Occurrence'
                                        ),
                                      ]
                                "
                                color="cyan"
                                v-model.trim="schedule.occurrence"
                                :loading="recurringLoading"
                                :disabled="
                                  recurringLoading || schedule.end_mode != 2
                                "
                                class="mx-2 width-100px"
                              ></v-text-field>
                            </td>
                            <td>occurrences</td>
                          </tr>
                        </table>
                      </template>
                    </v-radio>
                    <v-radio label="" :value="3" hide-details color="cyan">
                      <template v-slot:label>
                        <table>
                          <tr>
                            <td width="80">End after</td>
                            <td>
                              <v-menu
                                v-model="endDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    dense
                                    filled
                                    flat
                                    label="End Date"
                                    hide-details
                                    solo
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="
                                      schedule.end_mode != 3
                                        ? []
                                        : [
                                            validateRules.required(
                                              schedule.end_date,
                                              'End Date'
                                            ),
                                          ]
                                    "
                                    :value="formattedEndDate"
                                    color="cyan"
                                    :loading="recurringLoading"
                                    :disabled="
                                      recurringLoading || schedule.end_mode != 3
                                    "
                                    class="max-width-200px"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model.trim="schedule.end_date"
                                  color="cyan"
                                  :min="schedule.start_date"
                                  v-on:input="endDatePicker = false"
                                ></v-date-picker>
                              </v-menu>
                            </td>
                          </tr>
                        </table>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-container>
              </v-col>
              <v-col md="12" class="pb-0">
                <v-progress-linear
                  v-if="recurringLoading"
                  color="cyan"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
                <v-layout v-if="recurrenceMessage">
                  <v-flex class="mr-2">
                    <p
                      class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                    >
                      {{ recurrenceMessage }}
                    </p>
                  </v-flex>
                  <v-flex
                    v-if="visit_schedule && visit_schedule.length"
                    class="ml-2"
                  >
                    <v-btn
                      color="cyan white--text"
                      v-on:click="dateDialog = true"
                      class="custom-grey-border custom-bold-button h-100 w-100"
                      >Show Schedule</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="close_dialog()"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>

        <v-btn
          v-on:click="confirmDialog"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Save
        </v-btn>
      </template>
    </Dialog>

    <Dialog :common-dialog="dateDialog" :dialog-width="dialogWidth">
      <template v-slot:title
        >Visit Schedule ({{ visit_schedule.length }}) {{ getDuration() }}
      </template>
      <template v-slot:body>
        <v-container>
          <table width="100%">
            <tr
              v-for="(row, index) in visit_schedule"
              :key="index"
              :class="{ 'visit-row-hover': row.is_edited }"
            >
              <td
                class="font-size-14 font-weight-500"
                align="center"
                valign="middle"
              >
                {{ index + 1 }}.
              </td>
              <td>
                <Datepicker
                  solo
                  flat
                  :disabled="!row.status"
                  :default-date="row.visit_date"
                  v-model="row.visit_date"
                  v-on:change="row.is_edited = 1"
                  mandatory
                ></Datepicker>
              </td>
              <td>
                <v-text-field
                  readonly
                  solo
                  flat
                  dense
                  :disabled="!row.status"
                  filled
                  hide-details
                  :value="getDayName(row.visit_date)"
                ></v-text-field>
              </td>
              <td>
                <Timepicker
                  start-time-picker
                  :disabled="!row.status"
                  :default-time="row.start_time"
                  v-model="row.start_time"
                  v-on:change="row.is_edited = 1"
                  placeholder="Start Time"
                >
                </Timepicker>
              </td>
              <td>
                <Timepicker
                  end-time-picker
                  :disabled="!row.status"
                  :default-time="row.end_time"
                  v-model="row.end_time"
                  v-on:change="row.is_edited = 1"
                  placeholder="End Time"
                >
                </Timepicker>
              </td>
              <td align="center" valign="middle">
                <v-switch
                  class="m-0 p-0"
                  color="cyan"
                  dense
                  hide-details
                  :false-value="0"
                  :true-value="1"
                  v-on:change="row.is_edited = 1"
                  v-model="row.status"
                  inset
                ></v-switch>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="validateSchedule()"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="dateDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>

    <Dialog :common-dialog="dateValidateDialog" :dialog-width="dialogWidth">
      <template v-slot:title
        >Visit Schedule ({{ visit_schedule.length }}) {{ getDuration() }}
      </template>
      <template v-slot:body>
        <v-container>
          <v-layout>
            <v-flex md6 class="custom-border-right mr-2">
              <h3 class="color-custom-blue font-weight-600 font-size-19">
                Schedule Suggested by The System
              </h3>
              <table width="100%">
                <tr
                  v-for="(row, index) in raw_visit_schedule"
                  :key="index"
                  :class="{ 'visit-row-hover': row.is_edited }"
                >
                  <td
                    class="font-size-14 font-weight-500"
                    align="center"
                    valign="middle"
                  >
                    {{ index + 1 }}.
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ formatDate(row.visit_date) }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ getDayName(row.visit_date) }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.start_time || "-" }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.end_time || "-" }}
                  </td>
                  <td>
                    <v-icon color="green lighten-1" v-if="row.status"
                      >mdi-check</v-icon
                    >
                    <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex md6 class="ml-2">
              <h3 class="color-custom-blue font-weight-600 font-size-19">
                Changes Done by You
              </h3>
              <table width="100%">
                <template v-if="changed_visit_schedule.length">
                  <tr
                    v-for="(row, index) in changed_visit_schedule"
                    :key="index"
                    :class="{ 'visit-row-hover': row.is_edited }"
                  >
                    <td
                      class="font-size-14 font-weight-500"
                      align="center"
                      valign="middle"
                    >
                      {{ index + 1 }}.
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ formatDate(row.visit_date) }}
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ getDayName(row.visit_date) }}
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ row.start_time || "-" }}
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ row.end_time || "-" }}
                    </td>
                    <td>
                      <v-icon color="green lighten-1" v-if="row.status"
                        >mdi-check</v-icon
                      >
                      <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="6">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There is no changes done by you at the moment.
                    </p>
                  </td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="updateSchedule()"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="
            dateValidateDialog = false;
            dateDialog = true;
          "
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="confirm_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid style="text-align: center">
            <p class="font-weight-600 font-size-19" style="color: red">
              If you Re-schedule and Re-assign recurring visit then , all open
              visit will be deleted and create new visit with same data. ?
            </p>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <template>
          <v-btn
            :disabled="!formValid || recurringLoading"
            :loading="recurringLoading"
            class="mx-2 custom-bold-button white--text"
            v-on:click="updateOrCreate()"
            color="cyan"
          >
            Yes
          </v-btn>
        </template>
        <v-btn
          :disabled="recurringLoading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No
        </v-btn>
      </template>
    </Dialog>
    <v-layout v-if="false">
      <v-col md="12">
        <v-checkbox
          label="Schedule Later"
          :value="1"
          hide-details
          color="cyan"
          v-model.trim="schedule_later"
          class="mr-4 mt-0"
        ></v-checkbox>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import AppConfiguration from "@/core/config/app.config";
import RecurringPatternTemplate from "@/view/pages/job/partials/Recurring-Pattern-Template.vue";
import { QUERY, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { ProductFormEventBus } from "@/core/lib/product.from.lib.js";
import { toSafeInteger, isArray /* isEmpty */ } from "lodash";
import {
  ErrorEventBus,
  InitializeError,
  WeekEventBus,
} from "@/core/lib/message.lib";
//import { WeekEventBus } from "@/core/lib/message.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "recurring-schedule-body",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
    reassignScheduleDialog: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      yourFormat: "hh:mm A",
      dateDialog: false,
      dateValidateDialog: false,
      recurringLoading: false,
      schedule_later: 0,
      recurrenceMessage: null,
      startDatePicker: false,
      visit_pattern: {},
      statusLoading: false,
      confirm_dialog: false,
      recurring_pattern: {},
      scheduleCreated: false,
      recurring_dates: [],
      recurring_schedule: {},
      endDatePicker: false,
      rawStartTime: null,
      engineerList: [],
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      reassignEngineer: {
        technician: [],
      },
      schedule: {
        end_mode: 2,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        occurrence: 1,
        schedule_later: 0,
      },
      responseSchedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
      timeOut: null,
      timeOutLimit: 500,
      RecurringType: null,
      RecurringPattern: {},
      RecurringSchedule: {},
      changed_visit_schedule: [],
      raw_visit_schedule: [],
      visit_schedule: [],
    };
  },
  watch: {
    schedule: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.end_mode == 3) {
          if (param.end_date) {
            JobEventBus.$emit("update:recurring-schedule", param);
          }
        } else if (param.end_mode == 2) {
          const occurrence = this.lodash.toSafeInteger(param.occurrence);
          if (occurrence > 0) {
            JobEventBus.$emit("update:recurring-schedule", param);
          }
        } else {
          JobEventBus.$emit("update:recurring-schedule", param);
        }
      },
    },
    responseSchedule: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        if (_this.lodash.isEmpty(param) == false) {
          JobScheduleTeamEventBus.$emit("get:schedule-team", {
            start_date: param.start_date,
            end_date: param.end_date,
            start_time: param.start_time,
            end_time: param.end_time,
          });
        }
      },
    },
    reassignScheduleDialog: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.getAvailableTeam();
        }
      },
    },
    schedule_later(param) {
      JobScheduleTeamEventBus.$emit("update:schedule-later", !!+param);
    },
  },
  components: {
    Dialog,
    Datepicker,
    Timepicker,
    RecurringPatternTemplate,
    VueTimepicker,
  },
  methods: {
    confirmDialog() {
      this.confirm_dialog = true;
    },
    getAvailableTeam() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: _this.schedule.start_date
              ? moment(_this.schedule.start_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: _this.schedule.end_date
              ? moment(_this.schedule.end_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            start_time: _this.schedule.start_time
              ? moment(_this.schedule.start_time, ["h:mm A"]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.schedule.end_time
              ? moment(_this.schedule.end_time, ["h:mm A"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
            force: 0,
            all: 1,
            /*  skills: _this.skills, */
          },
        })
        .then(({ data }) => {
          _this.engineerList = data.engineers;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },
    close_dialog() {
      this.$emit("close", true);
    },
    updateMonthlyDayCount(param) {
      this.RecurringPattern["monthly"].day_count = moment(param).format("D");
      console.log(this.RecurringPattern);
    },
    update_time(date) {
      this.schedule.start_time = date.displayTime;
    },
    update_end_time(date) {
      this.schedule.end_time = date.displayTime;
    },
    getDayName(date) {
      return moment(date).format("dddd");
    },
    getDuration() {
      let timing = false;
      let start_date_time = this.responseSchedule.start_date;
      if (this.responseSchedule.start_time) {
        timing = true;
        start_date_time =
          start_date_time + " " + this.responseSchedule.start_time;
      }

      let end_date_time = this.responseSchedule.end_date;
      if (this.responseSchedule.end_time) {
        timing = true;
        end_date_time = end_date_time + " " + this.responseSchedule.end_time;
      }

      let result = [];

      if (timing) {
        result.push(this.formatDateTime(start_date_time));
        result.push(this.formatDateTime(end_date_time));
      } else {
        result.push(this.formatDate(start_date_time));
        result.push(this.formatDate(end_date_time));
      }

      return result.join(" - ");
    },
    validateSchedule() {
      this.changed_visit_schedule = this.lodash.differenceWith(
        this.visit_schedule,
        this.raw_visit_schedule,
        this.lodash.isEqual
      );
      this.$nextTick(() => {
        this.dateDialog = false;
        this.dateValidateDialog = true;
      });
    },
    updateSchedule() {
      JobEventBus.$emit("update:recurring-dates", this.visit_schedule);
      this.$nextTick(() => {
        this.dateDialog = false;
        this.dateValidateDialog = false;
      });
    },
    updateStartTimeAMPM(param) {
      let startTime = this.lodash.toString(this.schedule.start_time);
      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }
        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM(param) {
      let endTime = this.lodash.toString(this.schedule.end_time);
      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }
        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateStartTime(rawTime) {
      if (rawTime == "23:59") {
        rawTime = "00:00";
      }
      this.$nextTick(() => {
        this.rawStartTime = rawTime;
        this.$refs.rawStartTime.save(rawTime);
        this.schedule.start_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
      });
    },
    updateEndTime(rawTime) {
      if (rawTime == "00:00") {
        rawTime = "23:59";
      }
      this.$nextTick(() => {
        this.rawEndTime = rawTime;
        this.$refs.rawEndTime.save(rawTime);
        this.schedule.end_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
      });
    },
    getRecurrencePromise(type) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const start_time = _this.RecurringSchedule.start_time
            ? moment(_this.RecurringSchedule.start_time, ["hh:mm A"]).format(
                "HH:mm"
              )
            : null;
          const end_time = _this.RecurringSchedule.end_time
            ? moment(_this.RecurringSchedule.end_time, ["hh:mm A"]).format(
                "HH:mm"
              )
            : null;
          const recurrenceData = _this.RecurringPattern[type];
          let formData = new Object();
          switch (type) {
            case "daily": {
              formData = {
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                occurrence: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.occurrence
                ),
                start_date: _this.RecurringSchedule.start_date,
                end_date: _this.RecurringSchedule.end_date,
                recurring_pattern: _this.lodash.toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                start_time,
                end_time,
                end_mode: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.end_mode
                ),
              };
              break;
            }
            case "weekly": {
              formData = {
                weeks: _this.lodash.isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                occurrence: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.occurrence
                ),
                start_time,
                end_time,
                start_date: _this.RecurringSchedule.start_date,
                end_date: _this.RecurringSchedule.end_date,
                end_mode: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.end_mode
                ),
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: _this.lodash.toSafeInteger(recurrenceData.week_day),
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                month_count:
                  _this.lodash.toSafeInteger(
                    recurrenceData.recurring_pattern
                  ) === 1
                    ? _this.lodash.toSafeInteger(recurrenceData.day_month_count)
                    : _this.lodash.toSafeInteger(recurrenceData.month_count),
                recurring_pattern: _this.lodash.toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                occurrence: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.occurrence
                ),
                start_time,
                end_time,
                start_date: _this.RecurringSchedule.start_date,
                end_date: _this.RecurringSchedule.end_date,
                end_mode: _this.lodash.toSafeInteger(
                  _this.RecurringSchedule.end_mode
                ),
              };
              break;
            }
          }
          if (
            _this.lodash.isEmpty(recurrenceData) === false &&
            _this.lodash.isEmpty(formData) === false &&
            formData.start_date &&
            moment(formData.start_date).isValid()
          ) {
            if (
              type === "monthly" ||
              type === "bi_monthly" ||
              type === "quarterly" ||
              type === "bi_annually" ||
              type === "annually"
            ) {
              formData.day_count = moment(formData.start_date).format("D");
            }
            _this.$store
              .dispatch(QUERY, {
                url: "job/recurrence/" + type,
                data: formData,
              })
              .then((response) => {
                resolve(response.data);
                // WeekEventBus.$emit("validateWeek", false);
              })
              .catch((error) => {
                // WeekEventBus.$emit("validateWeek", true);
                reject(error);
              });
          } else {
            resolve({});
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getRecurrence() {
      const _this = this;
      clearTimeout(_this.timeOut);
      _this.recurringLoading = true;
      _this.RecurringType = _this.RecurringPattern.type;
      _this.recurrenceMessage = null;
      _this.timeOut = setTimeout(function () {
        _this.responseSchedule = new Object();
        _this.raw_visit_schedule = new Array();
        _this.visit_schedule = new Array();
        _this
          .getRecurrencePromise(_this.RecurringType)
          .then((response) => {
            if (_this.lodash.isEmpty(response) === false) {
              _this.responseSchedule = {
                start_date: response.start_date,
                end_date: response.end_date,
                start_time: response.start_time
                  ? moment(response.start_time, ["HH:mm"]).format("h:mm A")
                  : null,
                end_time: response.end_time
                  ? moment(response.end_time, ["HH:mm"]).format("h:mm A")
                  : null,
              };

              _this.recurrenceMessage = response.message;
              if (response.dates && response.dates.length) {
                _this.visit_schedule = response.dates.map((row) => {
                  return {
                    status: 1,
                    is_edited: 0,
                    visit_date: row,
                    start_time: _this.responseSchedule.start_time,
                    end_time: _this.responseSchedule.end_time,
                  };
                });
                _this.$nextTick(() => {
                  _this.raw_visit_schedule = _this.lodash.cloneDeep(
                    _this.visit_schedule
                  );
                  JobEventBus.$emit(
                    "update:recurring-dates",
                    _this.visit_schedule
                  );
                });
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
            JobEventBus.$emit("update:recurring-dates", new Array());
            JobEventBus.$emit("update:recurring-schedule", new Object());
          })
          .finally(() => {
            _this.recurringLoading = false;
          });
      }, _this.timeOutLimit);
    },
    async updateOrCreate() {
      const _this = this;
      this.recurringLoading = true;

      if (this.recurring_dates.length < 1) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Unable to create visits. Please try with valid duration."
          )
        );
        return false;
      }
      if (this.visit_pattern.pattern == "weekly") {
        if (this.visit_pattern.param.weekly.weeks.length !== 1) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(
              "Unable to create visits. Please try with valid duration."
            )
          );
          return false;
        }
      }

      if (this.visit_pattern.pattern == "fort_nightly") {
        if (this.visit_pattern.param.weekly.weeks.length !== 1) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(
              "Unable to create visits. Please try with valid duration."
            )
          );
          return false;
        }
      }

      if (this.visit_pattern.pattern === "twice_a_week") {
        if (this.visit_pattern.param.weekly.weeks.length !== 2) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(" Please select  2 days ")
          );
          return false;
        }
      }

      if (this.visit_pattern.pattern === "thrice_a_week") {
        if (this.visit_pattern.param.weekly.weeks.length !== 3) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please select  3 days ")
          );
          return false;
        }
      }

      if (_this.jobId > 0) {
        await _this.$store.dispatch(PUT, {
          url: "job-new/" + _this.jobId,
          data: {
            pattern: _this.recurring_pattern,
            schedule: _this.recurring_schedule,
          },
        });

        try {
          await _this.createRecurringSchedule({
            job: _this.jobId,
            dates: _this.recurring_dates,
            pattern: _this.recurring_pattern,
            schedule: _this.recurring_schedule,
          });
          _this.scheduleCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(
              "Recurring Schedule is not created. Please try again."
            )
          );
          return false;
        }
        /*   if (_this.scheduleCreated) { */

        try {
          if (
            _this.reassignEngineer.technician &&
            _this.reassignEngineer.technician.length > 0
          ) {
            await _this.assignRecurringTeam({
              job: _this.jobId,
              team: _this.reassignEngineer.technician,
            });
            /*    _this.engineerAssigned = true; */
          }
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(
              "Engineer are not allocated to Schedule. Please try again."
            )
          );
          return false;
        }
        /*      } */
      }
      _this.$nextTick(() => {
        _this.recurringLoading = false;
        _this.$router.push(_this.getDefaultRoute("visit", {}));
      });
    },
    createRecurringSchedule({ job, pattern, dates, schedule }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const recurrenceData = pattern[pattern.type];
          let formData = new Object();
          switch (pattern.type) {
            case "daily": {
              formData = {
                day_count: toSafeInteger(recurrenceData.day_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time
                  ? moment(_this.responseSchedule.start_time, [
                      "h:mm A",
                    ]).format("HH:mm")
                  : moment().startOf("day").format("HH:mm"),
                end_time: _this.responseSchedule.end_time
                  ? moment(_this.responseSchedule.end_time, ["h:mm A"]).format(
                      "HH:mm"
                    )
                  : moment().endOf("day").format("HH:mm"),
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "weekly": {
              formData = {
                weeks: isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: toSafeInteger(recurrenceData.week_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time
                  ? moment(_this.responseSchedule.start_time, [
                      "h:mm A",
                    ]).format("HH:mm")
                  : moment().startOf("day").format("HH:mm"),
                end_time: _this.responseSchedule.end_time
                  ? moment(_this.responseSchedule.end_time, ["h:mm A"]).format(
                      "HH:mm"
                    )
                  : moment().endOf("day").format("HH:mm"),
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: toSafeInteger(recurrenceData.week_day),
                day_count: toSafeInteger(recurrenceData.day_count),
                week_count: toSafeInteger(recurrenceData.week_count),
                month_count:
                  toSafeInteger(recurrenceData.recurring_pattern) === 1
                    ? toSafeInteger(recurrenceData.day_month_count)
                    : toSafeInteger(recurrenceData.month_count),
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.responseSchedule.start_date,
                end_date: _this.responseSchedule.end_date,
                start_time: _this.responseSchedule.start_time
                  ? moment(_this.responseSchedule.start_time, [
                      "h:mm A",
                    ]).format("HH:mm")
                  : moment().startOf("day").format("HH:mm"),
                end_time: _this.responseSchedule.end_time
                  ? moment(_this.responseSchedule.end_time, ["h:mm A"]).format(
                      "HH:mm"
                    )
                  : moment().endOf("day").format("HH:mm"),
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
          }

          if (!pattern.type) {
            reject("Invalid Request");
          }

          _this.$store
            .dispatch(PUT, {
              url: "job/" + job + "/" + pattern.type + "-recurrence",
              data: formData,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    assignRecurringTeam({ job, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(PUT, {
              url: "job/" + job + "/allocate",
              data: { engineer: team },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    formattedStartDate() {
      if (
        this.schedule.end_date &&
        moment(this.schedule.start_date).isAfter(this.schedule.end_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.schedule.start_date).format(Config.dateFormat);
      }
      return this.schedule.start_date;
    },
    formattedEndDate() {
      if (
        this.schedule.start_date &&
        moment(this.schedule.end_date).isBefore(this.schedule.start_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config && this.schedule.end_date) {
        return moment(this.schedule.end_date).format(Config.dateFormat);
      }
      return this.schedule.end_date;
    },
  },
  beforeMount() {
    if (!this.schedule.start_date) {
      this.schedule.start_date = new Date().toISOString().substr(0, 10);
    }
  },
  mounted() {
    /*   this.getAvailableTeam(); */
    JobScheduleTeamEventBus.$emit("update:schedule-later", false);

    ProductFormEventBus.$on("reload:recrence", (param) => {
      if (param) {
        this.getRecurrence();
      }
    });

    JobEventBus.$on("update:recurring-pattern", (argument) => {
      this.recurring_pattern = argument;
    });

    JobEventBus.$on("update:recurring-schedule", (argument) => {
      this.recurring_schedule = argument;
    });

    JobEventBus.$on("update:recurring-dates", (argument) => {
      this.recurring_dates = argument;
    });
    WeekEventBus.$on("visit-pattern-change", (param) => {
      this.visit_pattern = param;
    });
  },
  beforeDestroy() {
    /*JobEventBus.$off("update:scheduled-team");*/
    /*JobEventBus.$off("update:recurring-pattern");
        JobEventBus.$off("update:recurring-schedule");*/
    /*JobScheduleTeamEventBus.$off("update:assigned-team");*/
    /*JobEventBus.$off("update:recurring-pattern");
        JobEventBus.$off("update:recurring-schedule");*/
  },
  created() {
    const _this = this;
    /*JobEventBus.$on("update:scheduled-team", argument => {
          _this.schedule.schedule_later = 1;
          if (
            _this.lodash.isEmpty(argument) === false &&
            argument.assigned_team.length > 0
          ) {
            _this.schedule.schedule_later = 0;
          }
        });*/

    JobEventBus.$on("update:recurring-pattern", (argument) => {
      _this.RecurringPattern = argument;
      _this.getRecurrence();
    });

    JobEventBus.$on("update:recurring-schedule", (argument) => {
      _this.RecurringSchedule = argument;
      _this.getRecurrence();
    });

    /*JobScheduleTeamEventBus.$on("update:assigned-team", ({ assigned_team }) => {
          _this.schedule_later = 1;
          if (
            _this.lodash.isEmpty(assigned_team) === false &&
            assigned_team.length > 0
          ) {
            _this.schedule_later = 0;
          }
        });*/
  },
};
</script>
